import { useState, useEffect } from 'react';
import { Input } from '@ensdomains/thorin';
// import {EthEns} from 'web3-eth-ens';
// import {Eth} from 'web3-eth';
import './Tags.css';

const Web3Utils = require('web3-utils');

var Web3 = require('web3');
var web3 = new Web3(Web3.givenProvider || 'https://mainnet.infura.io/v3/f79786067a2f48019470cf11d54e949c');
console.log(web3.givenProvider);

function TagInput ({ tags, setTags }){
  const [tagData, setTagData] = useState(tags);
  const removeTagData = indexToRemove => {
    setTagData([...tagData.filter((_, index) => index !== indexToRemove)]);
    // setTags(tagData);
    
  };

  useEffect(() => {
    setTags(tagData)
  }, [tagData]);
 
  const getAfterAsterisk = (value) => {
    if(value.charAt(0) === '*' && value.charAt(1) === '.') 
      return value.substring(2);
    else
      return value;
  }

  const validENS = async (value) =>{
    var flag = false;
    value = getAfterAsterisk(value);
    console.log(value)
    await web3.eth.ens.getAddress(value)
    .then((address) => {
      if(address && address !== '0x0000000000000000000000000000000000000000'){
      flag = true;
      }  
    }).catch((err) => {
      alert(err.toString())
      console.error(err)
      flag=false;
    })
    return flag;
  }

  const addTagData = async (event) => {
    let value = event.target.value.trim();
    if(value === '' ) return;
    if(value.toLowerCase() === "*.eth"){
      alert("Cannot use *.eth to set file access");
      return;
    }
    if (Web3Utils.isAddress(value)) {
      setTagData([...tagData, value]);
      // setTags(tagData);
      value = '';
    } else {
      var isValid;
      await validENS(value)
      .then((result) =>{
        isValid = result;
      }).catch((err)=>{
        console.error(err)
        isValid = false;
      })
      if(!isValid){
        alert('Invalid address or ENS input');
      }
      else{
        setTagData([...tagData, value]);
        // setTags(tagData);
        value = '';  
      }
    }

  };
  return (
    <div className="tag-input">
      <ul className="tags">
        {tagData.map((tag, index) => (
          <li key={index} className="tag">
            <span className="tag-title">{tag}</span>
            <span
              className="tag-close-icon"
              onClick={() => removeTagData(index)}
            >
              x
            </span>
          </li>
        ))}
      </ul>
      <input
        type="text"
        onKeyUp={event => (event.key === 'Enter' ? addTagData(event) : null)}
        placeholder="Press enter to add a tag"
        style={{flex: 1, height: 46}}
      />
    </div>
  );
};

export default TagInput;
