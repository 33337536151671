import React, { useEffect, useState} from "react";
import { getItem } from "../ddb";
import { getENSOwned } from '../alchemy';
import {saveAs} from 'file-saver';
import toast, { Toaster }from 'react-hot-toast'
// import { Modal, Button, Heading, Card} from "@ensdomains/thorin";
import { Modal, Button, Text } from "@nextui-org/react";
import { useAccount } from 'wagmi';
import { useParams } from "react-router-dom";
import { findSubdomains } from "../utils/graph";
import { useConnectModal } from '@rainbow-me/rainbowkit';
import namehash from "@ensdomains/eth-ens-namehash";

var AES = require('crypto-js/aes');
var CryptoJS = require("crypto-js");
const Web3Utils = require('web3-utils');
var Web3 = require('web3');
var web3 = new Web3(Web3.givenProvider || 'https://mainnet.infura.io/v3/f79786067a2f48019470cf11d54e949c');

const isRecursiveSubdomain = (current_address, parent_domains) => {
  if (!parent_domains.subdomains) {
      return false;
  }
  for (let i = 0; i < parent_domains.subdomains.length; ++i) {
      if(parent_domains.subdomains[i].resolvedAddress){
        if(parent_domains.subdomains[i].resolvedAddress.id.toLowerCase() === current_address.toLowerCase()){
          return true;
        } if(isRecursiveSubdomain(current_address, parent_domains.subdomains[i])){
          return true;
        }
      }
  }
  return false;
}

export const isSubdomain = async (current_address, ensName) => {
  const data = await findSubdomains(ensName);
  console.log(data);
  if(!data.data.domains[0]){
      return false;
  }
  for (let i = 0; i < data.data.domains[0].subdomains.length; ++i) {
      if (data.data.domains[0].subdomains[i].resolvedAddress) {
        if(data.data.domains[0].subdomains[i].resolvedAddress.id.toLowerCase() === current_address.toLowerCase()){
          return true;
        } else if(isRecursiveSubdomain(current_address, data.data.domains[0])){
            return true;
        }
      }
  }
  return false;
}

// import { chains } from '@web3modal/ethereum'
// import { useContractWrite, useWaitForTransaction } from '@web3modal/react'
function DecryptFile(props) {
    const { openConnectModal } = useConnectModal();

    const [loading, setLoading] = useState(false);
    const [isPermitted, setPermission] = useState(false);
    const [fileHash, setFileHash] = useState("");
    const [fileName, setfileName] = useState();
    const { address, connector, isConnected } = useAccount()

    // const [result, setResult] = useState("");

    let { file_id } = useParams();
    const downloadFile = () => {
      const link = "https://ipfs.io/ipfs/" + fileHash;
      // fetch(link)
      //   .then(response => {
      //     response.blob().then(blob => {
      //       console.log(blob);
      //       let url = window.URL.createObjectURL(blob);
      //       let a = document.createElement('a');
      //       a.href = url;
      //       if(fileName)
      //         a.download = fileName;
      //       a.click();
      //     });
      // });
      fetch(link, {
        method: 'GET',
        })
        .then(function (response) {
                return response.blob();
            })
        .then(function(blob) {
            saveAs(blob, fileName);
        })
        .catch(error => {
            console.error("Failed to download", error);
            toast.error('Failed to download')
        })
    }
    const grantAccessToFile = async () =>{
      const result = await getItem("doc3", file_id);
      if(result.Item.encrypted && result.Item.encrypted === true){
        var bytes = AES.decrypt(result.Item.file_hash, process.env.REACT_APP_ENCRYPTION_KEY);
        result.Item.file_hash = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));  
      }
      setPermission(true);
      setFileHash(result.Item.file_hash);
      setfileName(result.Item.file_name);
    }
  
    const checkIfPermitted = async (current_address) =>{
        if(isConnected === false){
          openConnectModal();
        }
  
        let result = await getItem("doc3", file_id, "file_owner, permissionList");
        if(!result || !result.Item || !result.Item.file_owner|| !current_address){
          setPermission(false);
          return;
        }
        if(result.Item.file_owner.toLowerCase() === current_address.toLowerCase()){
          grantAccessToFile();
          return;
        }
        if(result.Item.network && result.Item.network !== "mainnet"){
          setPermission(false);
          return;
        }

        for (let i = 0; i < result.Item.permissionList.length; ++i){
          if(Web3Utils.isAddress(result.Item.permissionList[i])){
            if(result.Item.permissionList[i].toLowerCase() === current_address.toLowerCase()){
              grantAccessToFile();
              return;
            }
          } else if(result.Item.permissionList[i][0] !== "*") {
            var resolved_address = await web3.eth.ens.getAddress(result.Item.permissionList[i]);
            if(resolved_address.toLowerCase() === current_address.toLowerCase()){
              grantAccessToFile();
              return;
            }
          } else {
            var parent_name = result.Item.permissionList[i].substring(2);
            var checkIsSubdomain = await isSubdomain(current_address, parent_name);
            if(checkIsSubdomain){
              grantAccessToFile();
              return;
            }
          }
        }
        setPermission(false);
    }
    useEffect(()=>{
        if(props.visible){
          window.alert = function() {};
            console.log("decrypt modal")
            checkIfPermitted(props.current_address);
        }
    }, [props])

    useEffect(() => {

    })

    return (
      <Modal
      closeButton
      aria-labelledby="modal-title"
      open={props.visible}
      onClose={() => props.setVisible(false)}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Modal.Header>
        <Text id="modal-title" size={18}>
          Opening {window.location.href}<span style={{ fontWeight: "600" }}>{props.filename}</span>
        </Text>
      </Modal.Header>
      <Modal.Body>
        {
          loading ?
          
          <Button disabled>Decrypting...</Button>:
          isPermitted ?
          // <Button onClick={()=>{window.open("https://ipfs.io/ipfs/" + fileHash)}}>View File</Button>:
          <Button onClick={downloadFile}>Download File</Button>:
          <Button disabled>No Access</Button>
          
        }
      </Modal.Body>
    </Modal>

  )


}

export default DecryptFile;